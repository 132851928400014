
.btn.btn-default:hover{
   color:#121212;
}

.hoverItem:hover{
   border:1px solid black;
   color:black;
}


.crossHover:not(:hover){
   color:rgb(134, 136, 138);
 
   }
.crossHover:hover{
  color:#121212
 
}



.btn.btn-default{
    color:#3d3d3d;
    margin-left:20px;
    font-size: 20px;
    padding:2px

 }

.active{ 
 border:2px solid black !important;
}


.sticky-top{
   background-color: white;
}

.fixed-bottom{
   background-color: transparent;
   /* z-index: 800; */

}

/* .modal , .modal-content, .sticky-top .modal {
   z-index: 1000;
}  */

.modal-backdrop{
   z-index: 1000
} 


html,
body{
  width: 100%;
  height: 100vh;
  margin: 0;
  overflow: hidden;
}

#wrapper-canvas{
   height: 100%;
}

.m-border{
   border-bottom: #121212 1px solid;
   margin-top: 20px;
}


.border-b{
border-bottom: #121212 1px solid;
}